import React, {useState, useEffect} from "react";
import gifMinhoca from "../../img_react/gif_loading.gif";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RotatingLines } from "react-loader-spinner";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";

async function requestBackend(method, url, data_body){
    const response = await fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: data_body
    })
    return await response.json()
}



function ContainerBase(){
    const [menu, setMenu] = useState('investing')

    document.title = 'Administração MetaTrader'

    function NavLeft(){
        function SelectMenu(menu){
            setMenu(menu)
        }

        return (
            <nav className="navbar navbar-vertical navbar-expand-lg navbar-dark bg-dark h-100">
                <div className="container-fluid flex-column">
                    <a className="navbar-brand"><h3>Admin MetaTrader</h3></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav flex-column">
                            <li className="nav-item cursor_pointer" onClick={()=>SelectMenu('selecao')}>
                                {menu === 'selecao'?
                                    <a className="nav-link active" aria-current="page">Seleção</a>:
                                    <a className="nav-link" aria-current="page">Seleção</a>}
                            </li>
                            <li className="nav-item cursor_pointer" onClick={()=>SelectMenu('regiao')}>
                                {menu === 'regiao' ?
                                    <a className="nav-link active">Relacionar Seleção (Região) </a>:
                                    <a className="nav-link">Relacionar Seleção (Região) </a>}

                            </li>
                            <li className="nav-item cursor_pointer" onClick={()=>SelectMenu('quadros')}>
                                {menu === 'quadros' ?
                                    <a className="nav-link active">Relacionar Seleção (Quadros) </a>:
                                    <a className="nav-link">Relacionar Seleção (Quadros) </a>}

                            </li>

                            <li className="nav-item cursor_pointer" onClick={()=>SelectMenu('investing')}>
                                {menu === 'investing' ?
                                    <a className="nav-link active">Investing</a>:
                                    <a className="nav-link">Investing</a>}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }

    function LoadingContainer(){
        return (
            <div className="container-fluid border rounded mt-1 container_selection_moedas d-flex justify-content-center">
                <img src={gifMinhoca} alt="" width="100%" height="100%"/>
            </div>
        );
    }

    function NewContentSelection(){
        const url = '/admin_mt/'
        const [filtroMoeda, setFiltroMoeda] = useState('')
        const [moedas, setMoedas] = useState([])
        const [loading, setLoading] = useState(true)

        useEffect(()=>{
            const requestAllDatas = async() =>{
                await fetch(url)
                    .then((r)=> r.json())
                    .then((json)=>{
                        setMoedas(json['all_simbols']);
                        setLoading(false)
                    })
            }
            requestAllDatas();
        }, [])


         function BlockSearch(){
            function FilterMoedas(e){
                if(e.key === 'Enter'){
                    setFiltroMoeda(e.target.value)
                }
            }

            return (
                <div className="row mt-2">
                    <label className="col-1 d-flex align-items-center"><i>Pesquisa:</i></label>
                    <div className="col-11">
                        <input type="text" defaultValue={filtroMoeda} className="form-control rounded-4" placeholder="Filtre pelo nome do MetaTrader" onKeyUp={(e)=>FilterMoedas(e)}/>
                    </div>
                </div>
            );
        }

        function BtnSave(){

            const [showProgress, setShowProgress] = useState(false)
            const clickBtnSave = async () => {
                setShowProgress(true)
                let datas_request = await requestBackend('POST', url, JSON.stringify({'moedas': moedas}))
                setShowProgress(false)
                Swal.fire({
                    'text': datas_request['msg'],
                    'icon': 'success'
                })
            }

            return(
                <div className="row mt-1">
                    <div className="col-6 d-flex justify-content-between">
                        <RotatingLines
                          visible={showProgress}
                          color="#4fa94d"
                          width={50}
                          height={50}
                        />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <button type="button" className="btn btn-dark" onClick={clickBtnSave}>Salvar</button>
                    </div>

                </div>
            );
        }

        function BlockListMoedas(){

            function DeleteMoedaExists(nome){
                setMoedas(moedas.filter(moeda=>moeda.nome !== nome))
            }

            function MoedaMT5Exists(props){
                const classe_bg = props.temp ? 'bg-danger' : ''
                return (
                    <div className="row my-1">
                        <div className="col-11">
                            <div className="input-group">
                                <span className={`input-group-text ${classe_bg}`}>{props.nome}</span>
                                <input type="text" className="form-control" defaultValue={props.novo_nome} readOnly/>
                            </div>
                        </div>
                        <div className="col-1">
                            <button type="button" className="btn btn-danger w-100" onClick={()=> DeleteMoedaExists(props.nome)}><FontAwesomeIcon icon={faXmark}/></button>
                        </div>
                    </div>
                );
            }

            function NewMoedaMT5(){
                const [nomeMoeda, setNomeMoeda] = useState('')
                const [urlMoeda, setUrlMoeda] = useState('')
                const [temp, setTemp] = useState(false)

                function UpdateNomeMoeda(e){
                    setNomeMoeda(e.target.value)
                }

                function UpdateUrlMoeda(e){
                    setUrlMoeda(e.target.value)
                }

                function AddNewMoeda(){
                    let search_exists_name_moeda = moedas.filter(moeda=>moeda.nome === nomeMoeda)
                    if(search_exists_name_moeda.length === 0){
                        setMoedas([...moedas, {'nome': nomeMoeda, 'novo_nome': urlMoeda, 'temp': temp}])
                    }
                    else{
                        Swal.fire({
                            'text': 'Moeda ja existente!',
                            'icon': 'error'
                        })
                    }
                }
                return (
                    <div className="row my-1">
                        <div className="col-4">
                            <div className="form-floating">
                                <input type="text" defaultValue={nomeMoeda} onKeyUp={UpdateNomeMoeda} className="form-control form-control-sm"/>
                                <label>Nome no MetaTrader:</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-floating">
                                <input type="text" defaultValue={urlMoeda} onKeyUp={UpdateUrlMoeda} className="form-control form-control-sm"/>
                                <label>Novo Nome:</label>
                            </div>
                        </div>

                        <div className="col-4 d-flex align-items-center">
                            <input className="form-check-input" type="checkbox" checked={temp} onChange={()=>setTemp(!temp)}/>
                            <label className="form-check-label">
                                Temporaria ?
                            </label>
                        </div>
                        <div className="col-1">
                            <button type="button" className="btn btn-dark h-100" onClick={AddNewMoeda}>Adicionar</button>
                        </div>
                    </div>
                );
            }

            return (
                <div className="container-fluid border rounded mt-1 container_selection_moedas">
                    {moedas.filter((moeda) =>
                        moeda.nome.toUpperCase().includes(filtroMoeda.toUpperCase())
                    ).map((moeda, index)=>{
                        return <MoedaMT5Exists key={index} nome={moeda.nome} novo_nome={moeda.novo_nome} temp={moeda.temp}/>
                    })}

                    <NewMoedaMT5/>
                </div>
            );
        }

        return (
            <div>
                <BlockSearch/>
                {loading?
                    <LoadingContainer/>:
                    <BlockListMoedas/>}
                <BtnSave/>
            </div>
        );
    }


    function ContentRelationRegiao(){
        const url = '/admin_mt/relation_reg/'
        const [filtroMoeda, setFiltroMoeda] = useState('')
        const [moedas, setMoedas] = useState([])
        const [loading, setLoading] = useState(true)
        const [regioes, setRegioes] = useState([])

        useEffect(()=>{
            const requestAllDatas = async() =>{
                await fetch(url)
                    .then((r)=> r.json())
                    .then((json)=>{
                        setRegioes(json['list_regioes']);
                        setMoedas(json['moedas_regioes']);
                        setLoading(false)
                    })
            }
            requestAllDatas();
        }, [])


        function BlockSearch(){
            function FilterMoedas(e){
                if(e.key === 'Enter'){
                    setFiltroMoeda(e.target.value)
                }
            }

            return (
                <div className="row mt-2">
                    <label className="col-1 d-flex align-items-center"><i>Pesquisa:</i></label>
                    <div className="col-11">
                        <input type="text" defaultValue={filtroMoeda} className="form-control rounded-4" placeholder="Filtre pelo nome do MetaTrader" onKeyUp={(e)=>FilterMoedas(e)}/>
                    </div>
                </div>
            );
        }

        function BtnSave(){
            const [showProgress, setShowProgress] = useState(false)

            const clickBtnSave = async () => {
                setShowProgress(true)
                let datas_request = await requestBackend('POST', url, JSON.stringify({'moedas': moedas}))
                setShowProgress(false)
                Swal.fire({
                    'text': datas_request['msg'],
                    'icon': 'success'
                })
            }
            return(
                <div className="row mt-1">
                    <div className="col-6 d-flex justify-content-between">
                        <RotatingLines
                          visible={showProgress}
                          color="#4fa94d"
                          width={50}
                          height={50}
                        />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <button type="button" className="btn btn-dark" onClick={clickBtnSave}>Salvar</button>
                    </div>

                </div>
            );
        }

        function BlockListMoedas(){

            function ContainerMoeda(props){

                function UpdateRegiaoInMoeda(regiao_selected){
                    let moedas_now = [...moedas]
                    let index_obj = moedas.findIndex(moeda=>moeda.id===props.id && moeda.tipo===props.tipo)
                    let datas_update = {'nome': moedas_now[index_obj].nome, 'nome_show': moedas_now[index_obj].nome_show,
                                        'id': props.id, 'regiao': regiao_selected, 'tipo': moedas_now[index_obj].tipo}
                    moedas_now[index_obj] = Object.assign({}, moedas_now[index_obj], datas_update)
                    setMoedas(moedas_now)
                }

                function ChangeRegiao(e){
                    let value_regiao_selected = e.target.value
                    UpdateRegiaoInMoeda(value_regiao_selected)
                }

                function SelectRegiao(){
                    let regiao_selected = props.regiao_selected
                    return (
                        <div className="col-4">
                            <select className="form-select" value={regiao_selected} aria-label=".form-select-sm example" onChange={ChangeRegiao}>
                                <option value="0">---</option>
                                {regioes.map((regiao)=>(
                                    <option key={regiao.id} value={regiao.id}>{regiao.nome}</option>
                                ))}
                            </select>
                        </div>
                    );
                }
                function FormsRelationReg(){
                    return (
                        <div className="row">
                            <div className="col-8">
                                <div className="input-group">
                                    <span className="input-group-text">{props.nome}</span>
                                    <input type="text" className="form-control" defaultValue={props.nome_show} readOnly/>
                                </div>
                            </div>

                            <SelectRegiao/>

                        </div>
                    );
                }

                return (
                    <div className="col-12 my-1">
                        <FormsRelationReg/>
                    </div>
                );
            }

            return (
                <div className="container-fluid border rounded mt-1 container_selection_moedas">
                    {moedas.filter((moeda) => moeda.nome.toUpperCase().includes(filtroMoeda.toUpperCase())
                        ).map((moeda)=>{
                            return <ContainerMoeda key={moeda.id} id={moeda.id} tipo={moeda.tipo} nome={moeda.nome} nome_show={moeda.nome_show} regiao_selected={moeda.regiao}/>
                    })}

                </div>
            );
        }

        return (
            <div>
                <BlockSearch/>
                {loading?
                    <LoadingContainer/>:
                    <BlockListMoedas/>}
                <BtnSave/>
            </div>
        );
    }

    function ContentRelationQuadro(){

        const url = '/admin_mt/relation_quadros/'
        const [filtroMoeda, setFiltroMoeda] = useState('')
        const [moedas, setMoedas] = useState([])
        const [loading, setLoading] = useState(true)
        const [quadros, setQuadros] = useState([])

        useEffect(()=>{
            const requestAllDatas = async() => {
                await fetch(url)
                    .then((r)=> r.json())
                    .then((json)=>{
                        setQuadros(json['quadros']);
                        setMoedas(json['moedas']);
                        setLoading(false)
                    })
            }
            requestAllDatas();
        }, [])


        function BlockSearch(){
            function FilterMoedas(e){
                if(e.key === 'Enter'){
                    setFiltroMoeda(e.target.value)
                }
            }

            return (
                <div className="row mt-2">
                    <label className="col-1 d-flex align-items-center"><i>Pesquisa:</i></label>
                    <div className="col-11">
                        <input type="text" defaultValue={filtroMoeda} className="form-control rounded-4" placeholder="Filtre pelo nome do MetaTrader" onKeyUp={(e)=>FilterMoedas(e)}/>
                    </div>
                </div>
            );
        }

        function BtnSave(){

            const [showProgress, setShowProgress] = useState(false)
            const clickBtnSave = async () => {
                setShowProgress(true)
                let datas_request = await requestBackend('POST', url, JSON.stringify({'moedas': moedas}))
                setShowProgress(false)
                Swal.fire({
                    'text': datas_request['msg'],
                    'icon': 'success'
                })
            }
            return(
                <div className="row mt-1">
                    <div className="col-6 d-flex justify-content-between">
                        <RotatingLines
                          visible={showProgress}
                          color="#4fa94d"
                          width={50}
                          height={50}
                        />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <button type="button" className="btn btn-dark" onClick={clickBtnSave}>Salvar</button>
                    </div>

                </div>
            );
        }

        function BlockListMoedas(){

            function ContainerMoeda(props){

                function UpdateQdInMoeda(quadro_selected){
                    let moedas_now = [...moedas]
                    let index_obj = moedas.findIndex(moeda=>moeda.id===props.id && moeda.tipo === props.tipo)
                    let datas_update = {'nome': moedas_now[index_obj].nome, 'nome_show': moedas_now[index_obj].nome_show,
                                        'id': props.id, 'quadro': quadro_selected, 'tipo': moedas_now[index_obj].tipo}
                    moedas_now[index_obj] = Object.assign({}, moedas_now[index_obj], datas_update)
                    setMoedas(moedas_now)
                }

                function ChangeQuadro(e){
                    let value_qd_selected = e.target.value
                    UpdateQdInMoeda(value_qd_selected)
                }

                function SelectQuadro(){
                    let quadro_selected = props.quadro_selected
                    return (
                        <div className="col-4">
                            <select className="form-select" value={quadro_selected} aria-label=".form-select-sm example" onChange={ChangeQuadro}>
                                <option value="0">---</option>
                                {quadros.map((quadro)=>(
                                    <option key={quadro.id} value={quadro.id}>{quadro.nome}</option>
                                ))}
                            </select>
                        </div>
                    );
                }
                function FormsRelationQd(){
                    return (
                        <div className="row">
                            <div className="col-8">
                                <div className="input-group">
                                    <span className="input-group-text">{props.nome}</span>
                                    <input type="text" className="form-control" defaultValue={props.nome_show} readOnly/>
                                </div>
                            </div>

                            <SelectQuadro/>

                        </div>
                    );
                }

                return (
                    <div className="col-12 my-1">
                        <FormsRelationQd/>
                    </div>
                );
            }

            return (
                <div className="container-fluid border rounded mt-1 container_selection_moedas">
                    {moedas.filter((moeda) => moeda.nome.toUpperCase().includes(filtroMoeda.toUpperCase())
                        ).map((moeda)=>{
                            return <ContainerMoeda key={moeda.id} id={moeda.id} tipo={moeda.tipo} nome={moeda.nome} nome_show={moeda.nome_show} quadro_selected={moeda.quadro}/>
                    })}

                </div>
            );
        }

        return (
            <div>
                <BlockSearch/>
                {loading?
                    <LoadingContainer/>:
                    <BlockListMoedas/>}
                <BtnSave/>
            </div>
        );
    }

    function ContentInvesting(){
        const [filtroMoeda, setFiltroMoeda] = useState('')
        const [moedas, setMoedas] = useState([])
        const [loading, setLoading] = useState(true)
        const url = '/admin_mt/moedas_investing/'

        useEffect(()=>{
            const requestAllDatas = async() =>{
                await fetch(url)
                    .then((r)=> r.json())
                    .then((json)=>{
                        console.log(json)
                        setMoedas(json['moedas_investing']);
                        setLoading(false)
                    })
            }
            requestAllDatas();
        }, [])


         function BlockSearch(){
            function FilterMoedas(e){
                if(e.key === 'Enter'){
                    setFiltroMoeda(e.target.value)
                }
            }

            return (
                <div className="row mt-2">
                    <label className="col-1 d-flex align-items-center"><i>Pesquisa:</i></label>
                    <div className="col-11">
                        <input type="text" defaultValue={filtroMoeda} className="form-control rounded-4" placeholder="Filtre pelo nome do MetaTrader" onKeyUp={(e)=>FilterMoedas(e)}/>
                    </div>
                </div>
            );
        }

        function BtnSave(){

            const [showProgress, setShowProgress] = useState(false)
            const clickBtnSave = async () => {
                setShowProgress(true)
                let datas_request = await requestBackend('POST', url, JSON.stringify({'moedas': moedas}))
                setShowProgress(false)
                Swal.fire({
                    'text': datas_request['msg'],
                    'icon': 'success'
                })
            }

            return(
                <div className="row mt-1">
                    <div className="col-6 d-flex justify-content-between">
                        <RotatingLines
                          visible={showProgress}
                          color="#4fa94d"
                          width={50}
                          height={50}
                        />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <button type="button" className="btn btn-dark" onClick={clickBtnSave}>Salvar</button>
                    </div>

                </div>
            );
        }

        function BlockListMoedas(){

            function DeleteMoedaExists(nome){
                setMoedas(moedas.filter(moeda=>moeda.nome !== nome))
            }

            function MoedaInvestingExists(props){
                return (
                    <div className="row my-1">
                        <div className="col-11">
                            <div className="input-group">
                                <span className="input-group-text">{props.nome}</span>
                                <input type="text" className="form-control" defaultValue={props.url} readOnly/>
                            </div>
                        </div>
                        <div className="col-1">
                            <button type="button" className="btn btn-danger w-100" onClick={()=> DeleteMoedaExists(props.nome)}><FontAwesomeIcon icon={faXmark}/></button>
                        </div>
                    </div>
                );
            }

            function NewMoedaInvesting(){
                const [nomeMoeda, setNomeMoeda] = useState('')
                const [urlMoeda, setUrlMoeda] = useState('')

                function UpdateNomeMoeda(e){
                    setNomeMoeda(e.target.value)
                }

                function UpdateUrlMoeda(e){
                    setUrlMoeda(e.target.value)
                }

                function AddNewMoeda(){
                    let search_exists_name_moeda = moedas.filter(moeda=>moeda.nome === nomeMoeda)
                    if(search_exists_name_moeda.length === 0){
                        setMoedas([...moedas, {'nome': nomeMoeda, 'url': urlMoeda}])
                    }
                    else{
                        Swal.fire({
                            'text': 'Moeda ja existente!',
                            'icon': 'error'
                        })
                    }
                }
                return (
                    <div className="row my-1">
                        <div className="col-6">
                            <div className="form-floating">
                                <input type="text" defaultValue={nomeMoeda} onKeyUp={UpdateNomeMoeda} className="form-control form-control-sm"/>
                                <label>Nome:</label>
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="form-floating">
                                <input type="text" defaultValue={urlMoeda} onKeyUp={UpdateUrlMoeda} className="form-control form-control-sm"/>
                                <label>URL:</label>
                            </div>
                        </div>
                        <div className="col-1">
                            <button type="button" className="btn btn-dark h-100" onClick={AddNewMoeda}>Adicionar</button>
                        </div>
                    </div>
                );
            }

            return (
                <div className="container-fluid border rounded mt-1 container_selection_moedas">
                    {moedas.filter((moeda) =>
                        moeda.nome.toUpperCase().includes(filtroMoeda.toUpperCase())
                    ).map((moeda, index)=>{
                        return <MoedaInvestingExists key={index} nome={moeda.nome} url={moeda.url}/>
                    })}

                    <NewMoedaInvesting/>
                </div>
            );
        }

        return (
            <div>
                <BlockSearch/>
                {loading?
                    <LoadingContainer/>:
                    <BlockListMoedas/>}
                <BtnSave/>
            </div>
        );
    }

    function ReturnContentUse(){
        if(menu === 'selecao'){
            return <NewContentSelection/>
        }
        else if(menu === 'regiao'){
            return <ContentRelationRegiao/>
        }

        else if(menu === 'quadros'){
            return <ContentRelationQuadro/>
        }

        else{
            return <ContentInvesting/>
        }
    }

    return (
        <div className="container-fluid h-100 position-absolute">
            <div className="row h-100">
                <div className="col-3 h-100 p-0">
                    <NavLeft/>
                </div>
                <div className="col-9 bg-light">
                    <ReturnContentUse/>
                </div>
            </div>
        </div>
    );
}

export default ContainerBase;