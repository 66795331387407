import React, {useEffect, useState} from "react";
import Table from 'react-bootstrap/Table';
import {RiVerifiedBadgeFill} from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import Swal from "sweetalert2";
import {requestBackend} from "../Home/utils";
import {ModalConfirm} from "../Home/utils";

function Planos({csrf}){
    const [showModalConfirm, setShowModalConfirm] = useState(false)
    const [funcConfirm, setFuncConfirm] = useState(null)
    const [bodyModalConfirm, setBodyModalConfirm] = useState('')
    const [headerModalConfirm, setHeaderModalConfirm] = useState('')

    function HideModalConfirm(){
        setFuncConfirm(null)
        setBodyModalConfirm('')
        setHeaderModalConfirm('')
        setShowModalConfirm(false)
    }
    function CardPlans({plan}){
        const [namePlan, setNamePlan] = useState('')
        const [quantMonths, setQuantMonths] = useState('')
        const [priceReal, setPriceReal] = useState('')
        const [pricePromo, setPricePromo] = useState('')
        const [idPlanEdit, setIdPlanEdit] = useState(null)
        const [allPlans, setAllPlans] = useState([])

        useEffect(() => {
            async function getPlans(){
                const request = await requestBackend('GET','/users/crud_plan/', {'plan': plan}, csrf)
                setAllPlans(await request['allPlans'])
            }
            getPlans()
        }, []);

        function RowTable({objPlan}){

            function EditPlan(){
                setIdPlanEdit(objPlan.id)
                setPricePromo(objPlan.promo)
                setPriceReal(objPlan.real)
                setQuantMonths(objPlan.months)
                setNamePlan(objPlan.name)
            }

            function ShowModalConfirm(){
                setShowModalConfirm(true)
                setFuncConfirm(()=> DeletePlan)
                setBodyModalConfirm('Ao Excluir um Plano você vai cancelar todas as assinaturas dos usuários!!\nTem certeza que deseja prosseguir ?')
                setHeaderModalConfirm('Excluir Plano')
            }

            async function DeletePlan(){
                await requestBackend('DELETE','/users/crud_plan/' + objPlan.id + '/', null, csrf)
                setAllPlans(allPlans.filter(planzin=>planzin.id !== objPlan.id))
                HideModalConfirm()
            }
            return (
                <tr>
                    <td>{objPlan.name}</td>
                    <td>{objPlan.months}</td>
                    <td className="text-center">{objPlan.real}</td>
                    <td className="text-center">{objPlan.promo}</td>
                    <td className="text-center">
                        <div onClick={EditPlan} className="px-2 font_size_footer bg-opacity-25 d-inline-flex align-items-center bg-secondary text-white cursor_pointer rounded-4 w-auto">
                            <FaRegEdit className="me-2" size={20}/>Editar
                        </div>

                        <div onClick={ShowModalConfirm} className="px-2 font_size_footer bg-opacity-25 d-inline-flex align-items-center bg-danger text-danger cursor_pointer rounded-4 w-auto">
                            <TiDelete className="me-2" size={20}/>Excluir
                        </div>
                    </td>
                </tr>
            );
        }

        async function SavePlan(){
            if(namePlan.length > 0 && quantMonths !== '' && priceReal !== ''){
                if(idPlanEdit === null){
                    const request = await requestBackend('POST', '/users/crud_plan/', {'planzada': {'id': null, 'name': namePlan, 'plan': plan, 'months': quantMonths, 'real': priceReal, 'promo': pricePromo}}, csrf)
                    const newId = await request['newId']
                    const objAdd = {name: namePlan, months: quantMonths, real: priceReal, promo: pricePromo, id: newId}
                    setAllPlans([...allPlans, objAdd])
                }
                else{
                    await requestBackend('POST', '/users/crud_plan/', {'planzada': {'id': idPlanEdit, 'name': namePlan, 'plan': plan, 'months': quantMonths, 'real': priceReal, 'promo': pricePromo}}, csrf)
                    setAllPlans(allPlans.map(objPlan=>objPlan.id === idPlanEdit ? {...objPlan, name: namePlan, months: quantMonths, real: priceReal, promo: pricePromo} : objPlan))
                }
                setIdPlanEdit(null)
                setQuantMonths('')
                setPriceReal('')
                setPricePromo('')
                setNamePlan('')
            }
            else{
                Swal.fire({
                    text: 'Digite os campos necessarios',
                    icon: "error"
                })
            }
        }

        return (
            <div className="card bg_login rounded-4">
                <div
                    className="card-header font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                    {plan}
                </div>
                <div className="card-body roboto-regular">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-3">
                                <div className="form-floating">
                                    <input type="text" defaultValue={namePlan}
                                           onChange={(e) => setNamePlan(e.target.value)} className="form-control"
                                           id={`name_${plan}`}/>
                                    <label htmlFor={`name_${plan}`}>Nome do plano:</label>
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="form-floating ">
                                    <input type="number" defaultValue={quantMonths}
                                           onChange={(e) => setQuantMonths(e.target.value)} className="form-control"
                                           id={`quant_months_${plan}`}/>
                                    <label htmlFor={`quant_months_${plan}`}>Meses de Duração:</label>
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="form-floating ">
                                    <input type="number" defaultValue={priceReal}
                                           onChange={(e) => setPriceReal(e.target.value)} className="form-control"
                                           id={`real_price_${plan}`}/>
                                    <label htmlFor={`real_price_${plan}`}>Preço Real:</label>
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="form-floating">
                                    <input type="number" defaultValue={pricePromo}
                                           onChange={(e) => setPricePromo(e.target.value)} className="form-control"
                                           id={`real_promo_${plan}`}/>
                                    <label htmlFor={`real_promo_${plan}`}>Preço Promo:</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 d-flex justify-content-end mt-2">
                            <button className="btn btn_pattern w-auto rounded-4" type="button" onClick={SavePlan}>
                                Salvar
                            </button>
                        </div>

                        <Table className="rounded-3 mt-2" hover variant="dark">
                            <thead>
                                <tr>
                                    <th>Nome do plano</th>
                                    <th>Meses de Duração</th>
                                    <th className="text-center">Preço Real</th>
                                    <th className="text-center">Preço Promo</th>
                                    <th className="text-center">Ações</th>
                                </tr>
                            </thead>
                          <tbody>
                            {allPlans.map((objPlan, index)=>(
                                <RowTable objPlan={objPlan} key={index}/>
                            ))}
                          </tbody>
                        </Table>

                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid p-3">
            <div className="col-12 font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                Planos
            </div>

            <div className="container-fluid rounded-3">
                <div className="row">
                    <div className="col-6">
                        <CardPlans plan={'PLANO 1'}/>
                    </div>

                    <div className="col-6">
                        <CardPlans plan={'PLANO 2'}/>
                    </div>
                </div>
            </div>
            <ModalConfirm show={showModalConfirm} onConfirm={funcConfirm} header={headerModalConfirm} message={bodyModalConfirm} onHide={HideModalConfirm}/>
        </div>
    );
}

export default Planos