import React from "react";
import isEmail from "validator/es/lib/isEmail";
import cpf from 'cpf-check';
export const emailValid = (email) => {
    return isEmail(email)
}
export const passwordForce = (password) => {
    let score = 0;

    if (password.length >= 8) {
      score++;
    }

    if (password.match(/[a-z]/)) {
      score++;
    }

    if (password.match(/[A-Z]/)) {
      score++;
    }

    if (password.match(/\d+/)) {
      score++;
    }

    if (password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
      score++;
    }

    return score
}

export const foneValid = (fone) => {
    const phoneRegex = /^\([1-9]{2}\) [2-9][0-9]{3,4}\-[0-9]{4,5}$/; // Exemplo de regex para telefone brasileiro
    return phoneRegex.test(fone);
}

export const cpfValid = (cpfValue) => {
    return cpf.validate(cpfValue);
}

export const removeFormatCpf = (cpf) => {
    return cpf.replace(/\D/g, '');
}

export const formatarCPF = (cpfValue) => {
    // Remove todos os caracteres não numéricos
    const numerosApenas = cpfValue.replace(/\D/g, '');

    // Formata o CPF no formato XXX.XXX.XXX-XX
    return numerosApenas.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export const formatarNumeroTelefone = (numero) => {
    // Remove qualquer caractere não numérico
    const numerosApenas = numero.replace(/\D/g, '');

    // Verifica o comprimento do número e formata de acordo
    if (numerosApenas.length === 0) {
        return ''; // Se não houver números, retorna uma string vazia
    } else if (numerosApenas.length <= 2) {
        return `(${numerosApenas}`;
    } else if (numerosApenas.length <= 6) {
        return `(${numerosApenas.slice(0, 2)}) ${numerosApenas.slice(2)}`;
    } else if (numerosApenas.length <= 10) {
        return `(${numerosApenas.slice(0, 2)}) ${numerosApenas.slice(2, 6)}-${numerosApenas.slice(6)}`;
    } else {
        return `(${numerosApenas.slice(0, 2)}) ${numerosApenas.slice(2, 7)}-${numerosApenas.slice(7, 11)}`;
    }
}