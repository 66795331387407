import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";
import {faUnlock} from "@fortawesome/free-solid-svg-icons/faUnlock";
import logoSQ from "../../img_react/logo_superquant.png";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons/faUserPlus";
import {faRightToBracket} from "@fortawesome/free-solid-svg-icons/faRightToBracket";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {faIdCard} from "@fortawesome/free-solid-svg-icons/faIdCard";
import {faMobileRetro} from "@fortawesome/free-solid-svg-icons/faMobileRetro";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faBackward} from "@fortawesome/free-solid-svg-icons/faBackward";
import {cpfValid, passwordForce, foneValid, emailValid, formatarNumeroTelefone, formatarCPF} from "./Validators";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { FaBarcode } from "react-icons/fa6";
import {getCsrfToken, requestBackend} from "./utils";
import Swal from "sweetalert2";


function ContentLogin({ csrf, setMenu , setUserLogged}){

    function ContainerLogin() {
        const [menuLoginSelected, setMenuLoginSelected] = useState('Login')
        const [firstNameGoogle, setFirstNameGoogle] = useState('')
        const [secondNameGoogle, setSecondNameGoogle] = useState('')
        const [emailGoogle, setEmailGoogle] = useState('')
        const [readOnlyForms, setReadOnlyForms] = useState(false)
        const [classFormsValid, setClassFormsValid] = useState('')

        function ContentRegister(){

            const [typeFormSenha, setTypeFormSenha] = useState('password')
            const [iconPassword, setIconPassword] = useState(<FontAwesomeIcon icon={faLock} className="cursor_pointer" onClick={() => changeIconPassword(true)}/>)
            const [formName, setFormName] = useState({value: firstNameGoogle, classForm: classFormsValid})
            const [formSecondName, setFormSecondName] = useState({value: secondNameGoogle, classForm: classFormsValid})
            const [formCpf, setFormCpf] = useState({value: '', classForm: ''})
            const [formEmail, setFormEmail] = useState({value: emailGoogle, classForm: classFormsValid})
            const [formFone, setFormFone] = useState({value: '', classForm: ''})
            const [formPassword, setFormPassword] = useState({value: '', classForm: ''})

            function changeIconPassword(showPassword){
                if(showPassword){
                    setIconPassword(<FontAwesomeIcon icon={faUnlock} className="cursor_pointer" onClick={() => changeIconPassword(false)}/>)
                    setTypeFormSenha('text')
                }
                else{
                    setIconPassword(<FontAwesomeIcon icon={faLock} className="cursor_pointer" onClick={() => changeIconPassword(true)}/>)
                    setTypeFormSenha('password')

                }
            }

            function StateFormOk(state){
                return !(state.classForm === "is-invalid" || state.classForm === "")
            }
            function ChangeCpf(cpfValue){
                const valid = cpfValid(cpfValue)
                const classValid = !valid ? 'is-invalid' : 'is-valid'
                setFormCpf({value: formatarCPF(cpfValue), classForm: classValid})
            }

            function ChangePassword(passwordValue){
                const force = passwordForce(passwordValue)
                const classValid = force === 0 ? 'is-invalid' : 'is-valid'
                setFormPassword({value: passwordValue, classForm: classValid})
            }

            function ChangeEmail(emailValue){
                const emailOk = emailValid(emailValue)
                const classValid = emailOk ? 'is-valid' : 'is-invalid'
                setFormEmail({value: emailValue, classForm: classValid})
            }

            function ChangeNome(nomeValue){
                const classValid = nomeValue.length === 0 ? 'is-invalid' : 'is-valid'
                setFormName({value: nomeValue, classForm: classValid})
            }

            function ChangeSobrenome(sobrenomeValue){
                const classValid = sobrenomeValue.length === 0 ? 'is-invalid' : 'is-valid'
                setFormSecondName({value: sobrenomeValue, classForm: classValid})
            }

            function ChangePhone(phoneValue){
                const classValid = foneValid(phoneValue) ? 'is-valid': 'is-invalid'
                const format= formatarNumeroTelefone(phoneValue)
                setFormFone({value: format, classForm: classValid})
            }

            function VerifyDatasFormsOk(){
                const nomeOk = StateFormOk(formName)
                const sobrenomeOk = StateFormOk(formSecondName)
                const cpfOk = StateFormOk(formCpf)
                const phoneOk = StateFormOk(formFone)
                const emailOk = StateFormOk(formEmail)
                const passwordOk = StateFormOk(formPassword)
                return nomeOk && sobrenomeOk && cpfOk && phoneOk && emailOk && passwordOk
            }

            async function ClickButtonSave(){
                const formsOk = VerifyDatasFormsOk()
                if(formsOk){
                    let datasRequest = await requestBackend('POST','/users/register_new_user/',
                        {'nome': formName.value, 'sobrenome': formSecondName.value,
                            'email': formEmail.value, 'fone': formFone.value, 'cpf': formCpf.value,
                            'password': formPassword.value}, csrf)
                    let datasReturn = await datasRequest['datas']
                    if(!datasReturn['error']){
                        Swal.fire({
                            'text': 'Usuário Criado, agora entre em contato com o administrador para liberar o seu acesso!',
                            'icon': 'success'
                        })
                        // setUserLogged(true)
                        // setMenu('Carteira')
                    }
                    else{
                        Swal.fire({
                            'text': datasReturn['msg_return'],
                            'icon': 'error'
                        })
                    }
                }
            }

            return (
                <div className="container-fluid font_color_navmenu d-flex align-content-center py-5 px-5">
                    <div className="row g-3 animate__animated animate__backInLeft">
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mb-2">
                            <label>Nome:<span className="text-primary">*</span></label>
                            <div className="input-group">
                                <div className="input-group-text"><FontAwesomeIcon icon={faUser}/></div>
                                <input type="text" className={`form-control z-index-0 ${formName.classForm}`} placeholder="Digite seu nome"
                                       defaultValue={formName.value} readOnly={readOnlyForms} onChange={(e)=>ChangeNome(e.target.value)}/>
                            </div>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 mb-2">
                            <label>Sobrenome:<span className="text-primary">*</span></label>
                            <div className="input-group">
                                <div className="input-group-text"><FontAwesomeIcon icon={faUsers}/>
                                </div>
                                <input type="text" className={`form-control z-index-0 ${formSecondName.classForm}`}
                                       placeholder="Digite seu sobrenome"
                                       defaultValue={formSecondName.value} readOnly={readOnlyForms} onChange={(e)=>ChangeSobrenome(e.target.value)}/>
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mb-2">
                            <label>CPF:<span className="text-primary">*</span></label>
                            <div className="input-group">
                                <div className="input-group-text"><FontAwesomeIcon icon={faIdCard}/>
                                </div>
                                <input type="text" className={`form-control z-index-0 ${formCpf.classForm}`} placeholder="000.000.000-00" value={formCpf.value} onChange={(e) => ChangeCpf(e.target.value)}/>
                            </div>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 mb-2">
                            <label>Telefone:<span className="text-primary">*</span></label>
                            <div className="input-group">
                                <div className="input-group-text"><FontAwesomeIcon
                                    icon={faMobileRetro}/></div>
                                <input type="text" className={`form-control z-index-0 ${formFone.classForm}`} placeholder="(00) 00000-0000" value={formFone.value} onChange={(e)=>ChangePhone(e.target.value)}/>
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mb-2">
                            <label>Email<span className="text-primary">*</span></label>
                            <div className="input-group">
                                <div className="input-group-text"><FontAwesomeIcon icon={faEnvelope}/>
                                </div>
                                <input type="text" className={`form-control z-index-0 ${formEmail.classForm}`}
                                       placeholder="Digite seu e-mail" defaultValue={formEmail.value} onChange={(e)=>ChangeEmail(e.target.value)} readOnly={readOnlyForms}/>
                            </div>
                        </div>


                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 mb-2">
                            <label>Senha<span className="text-primary">*</span></label>
                            <div className="input-group">
                                <div className="input-group-text">{iconPassword}</div>
                                <input type={typeFormSenha} className={`form-control z-index-0 ${formPassword.classForm}`}
                                       placeholder="Digite sua senha"
                                       defaultValue={formPassword.value} onChange={(e)=>ChangePassword(e.target.value)}/>
                            </div>
                        </div>

                        <div className="col-12">
                            <button
                                className="btn btn_pattern fw-bold px-4 float-start mt-4"
                                onClick={() => setMenuLoginSelected('Login')}><FontAwesomeIcon
                                icon={faBackward}/> Voltar
                            </button>
                            <button
                                className="btn btn_pattern fw-bold px-4 float-end mt-4" onClick={ClickButtonSave}>
                                Salvar <FontAwesomeIcon icon={faRightToBracket}/>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        function ContentLogin() {

            const [emailLogin, setEmailLogin] = useState({value: '', classForm: ''})
            const [passwordLogin, setPasswordLogin] = useState({value: '', classForm: ''})
            const [typeFormSenha, setTypeFormSenha] = useState('password')
            const [iconPassword, setIconPassword] = useState(<FontAwesomeIcon icon={faLock} className="cursor_pointer" onClick={() => changeIconPassword(true)}/>)

            function changeIconPassword(showPassword){
                if(showPassword){
                    setIconPassword(<FontAwesomeIcon icon={faUnlock} className="cursor_pointer" onClick={() => changeIconPassword(false)}/>)
                    setTypeFormSenha('text')
                }
                else{
                    setIconPassword(<FontAwesomeIcon icon={faLock} className="cursor_pointer" onClick={() => changeIconPassword(true)}/>)
                    setTypeFormSenha('password')

                }
            }

            function StateFormOk(state){
                return !(state.classForm === "is-invalid" || state.classForm === "")
            }
            async function testReturnGoogle(response) {
                const credentials = response.credential
                const credentialsDecode = jwtDecode(credentials)
                const email = credentialsDecode.email
                const requestUserExists = await requestBackend('GET', '/users/user_exists/', {'email': email}, csrf)
                const userExists = await requestUserExists['userExists']
                if(userExists){
                    const request = await requestBackend('GET', '/users/log_user_with_google/', {'email': email}, csrf)
                    const userActive = await request['userActive']
                    if(userActive){
                        setMenu('Carteira')
                        setUserLogged(true)
                    }
                    else{
                        Swal.fire({
                            'text': 'Usuário Inativo, entre em contato com o Administrador',
                            'icon': 'error'
                        })
                    }

                }
                else{
                    setEmailGoogle(email)
                    setFirstNameGoogle(credentialsDecode.given_name)
                    setSecondNameGoogle(credentialsDecode.family_name)
                    setReadOnlyForms(true)
                    setClassFormsValid('is-valid')
                    setMenuLoginSelected('Register')
                    // mandar usuario pra tela de finalização do cadastro
                }
            }

            function ChangePassword(passwordValue){
                const force = passwordForce(passwordValue)
                const classValid = force === 0 ? 'is-invalid' : 'is-valid'
                setPasswordLogin({value: passwordValue, classForm: classValid})
            }

            function ChangeEmail(emailValue){
                const emailOk = emailValid(emailValue)
                const classValid = emailOk ? 'is-valid' : 'is-invalid'
                setEmailLogin({value: emailValue, classForm: classValid})
            }

            function VerifyDatasLogin(){
                const emailOk = StateFormOk(emailLogin)
                const passwordOk = StateFormOk(passwordLogin)
                return emailOk && passwordOk
            }

            async function ClickButtonLogin(){
                const datasOk = VerifyDatasLogin()
                if(datasOk){
                    const requestVerifyLogin = await requestBackend('GET', '/users/verify_login/', {'email': emailLogin.value, 'password': passwordLogin.value}, csrf)
                    const datasReturn = await requestVerifyLogin['datas']
                    if(!datasReturn['error']){
                        setUserLogged(true)
                        setMenu('Carteira')
                    }
                    else{
                        Swal.fire({
                            'text': datasReturn['msg_return'],
                            'icon': 'error'
                        })
                    }
                }
            }


            return (
                <div className="form-left h-100 py-5 px-5 font_color_navmenu animate__animated animate__backInLeft">
                    <div className="row g-4">
                        <div className="col-12">
                            <label>Email<span className="text-primary">*</span></label>
                            <div className="input-group">
                                <div className="input-group-text"><FontAwesomeIcon icon={faUser}/></div>
                                <input type="text" className={`form-control z-index-0 ${emailLogin.classForm}`} defaultValue={emailLogin.value} onChange={(e)=>ChangeEmail(e.target.value)} placeholder="Digite seu e-mail"/>
                            </div>
                        </div>

                        <div className="col-12">
                            <label>Senha<span className="text-primary">*</span></label>
                            <div className="input-group">
                                <div className="input-group-text">{iconPassword}</div>
                                <input type={typeFormSenha} className={`form-control z-index-0 ${passwordLogin.classForm}`} defaultValue={passwordLogin.value} onChange={(e)=>ChangePassword(e.target.value)} placeholder="Digite sua senha"/>
                            </div>
                        </div>


                        <div className="col-sm-6">
                            <a className="float-start text-primary cursor_pointer" onClick={()=>setMenuLoginSelected('Forget')}>Esqueceu a senha?</a>
                        </div>

                        <div className="col-12">
                            <button
                                className="btn btn_pattern fw-bold px-4 float-start mt-4"
                                onClick={() => setMenuLoginSelected('Register')}>Registrar <FontAwesomeIcon
                                icon={faUserPlus}/>
                            </button>
                            <button
                                className="btn btn_pattern fw-bold px-4 float-end mt-4" onClick={ClickButtonLogin}>Login <FontAwesomeIcon
                                icon={faRightToBracket}/>
                            </button>
                        </div>

                        <div className="col-12">
                            <GoogleLogin onSuccess={testReturnGoogle} theme="filled_blue" text="continue_with"/>
                        </div>
                    </div>
                </div>
            );
        }

        function ContentForgetPassword(){
            const [emailForget, setEmailForget] = useState({value: '', classForm: ''})
            const [menuInForget, setMenuInForget] = useState('Email')
            const [hash, setHash] = useState({value: '', classForm: ''})
            const [password1, setPassword1] = useState({value: '', classForm: ''})
            const [typeFormSenha1, setTypeFormSenha1] = useState('password')
            const [iconPassword1, setIconPassword1] = useState(<FontAwesomeIcon icon={faLock} className="cursor_pointer" onClick={() => changeIconPassword(true, setIconPassword1, setTypeFormSenha1)}/>)

            const [password2, setPassword2] = useState({value: '', classForm: ''})
            const [typeFormSenha2, setTypeFormSenha2] = useState('password')
            const [iconPassword2, setIconPassword2] = useState(<FontAwesomeIcon icon={faLock} className="cursor_pointer" onClick={() => changeIconPassword(true, setIconPassword2, setTypeFormSenha2)}/>)

            function changeIconPassword(showPassword, stateIcon, stateTypeForm){
                if(showPassword){
                    stateIcon(<FontAwesomeIcon icon={faUnlock} className="cursor_pointer" onClick={() => changeIconPassword(false, stateIcon, stateTypeForm)}/>)
                    stateTypeForm('text')
                }
                else{
                    stateIcon(<FontAwesomeIcon icon={faLock} className="cursor_pointer" onClick={() => changeIconPassword(true, stateIcon, stateTypeForm)}/>)
                    stateTypeForm('password')

                }
            }
            function ChangeEmail(emailValue){
                const emailOk = emailValid(emailValue)
                const classValid = emailOk ? 'is-valid' : 'is-invalid'
                setEmailForget({value: emailValue, classForm: classValid})
            }

            function ChangeHash(hashValue){
                const hashOk = hashValue.length === 5
                const classValid = hashOk ? 'is-valid' : 'is-invalid'
                setHash({value: hashValue, classForm: classValid})
            }

            function StateFormOk(state){
                return !(state.classForm === "is-invalid" || state.classForm === "")
            }

            async function SendEmailGenerateHash(){
                const emailOk = StateFormOk(emailForget)
                if(emailOk){
                    const request = await requestBackend('GET', '/users/forget_password/', {'emailUser': emailForget.value}, csrf)
                    const objReturn = await request['return']
                    if(!objReturn.error){
                        Swal.fire({
                            'text': objReturn['msg_return'],
                            'icon': 'success'
                        })
                        setMenuInForget('Valid')
                    }
                    else{
                        Swal.fire({
                            'text': objReturn['msg_return'],
                            'icon': 'error'
                        })
                    }
                }
            }

            async function SendHashVerify(){
                const hashOk = StateFormOk(hash)
                if(hashOk){
                    const request = await requestBackend('GET', '/users/verify_hash/', {'emailUser': emailForget.value, 'hash': hash.value}, csrf)
                    const objReturn = await request['return']
                    if(objReturn['valid']){
                        setMenuInForget('NewPass')
                    }
                    else{
                        Swal.fire({
                            'text': objReturn['msg_return'],
                            'icon': 'error'
                        })
                    }
                }
            }

            function ChangePassword2(value){
                const password2Ok = value === password1.value
                const classPass2 = password2Ok ? 'is-valid' : 'is-invalid'
                setPassword2({value: value, classForm: classPass2})
            }

            function ChangePassword1(passwordValue){
                const force = passwordForce(passwordValue)
                const classValid = force === 0 ? 'is-invalid' : 'is-valid'
                setPassword1({value: passwordValue, classForm: classValid})
                if(password2.value.length > 0){
                    if(password1.value !== password2.value){
                        setPassword2({...password2, classForm: 'is-invalid'})
                    }
                    else{
                        setPassword2({...password2, classForm: 'is-valid'})
                    }
                }
            }

            async function UpdatePasswordUser(){
                const pass1Ok = StateFormOk(password1)
                const pass2Ok = StateFormOk(password2)
                if(pass1Ok && pass2Ok){
                    const request = await requestBackend('POST', '/users/verify_hash/', {'emailUser': emailForget.value, 'password': password2.value}, csrf)
                    const objReturn = await request['return']
                    Swal.fire({
                        'text': objReturn.msg_return,
                        'icon': 'success'
                    })
                    setMenuLoginSelected('Login')

                }
            }
            return (
                <div className="form-left h-100 py-5 px-5 font_color_navmenu animate__animated animate__backInLeft">
                    <div className="row g-4">
                        <div className="col-12 chakra-petch-regular fw-bold font_size_headers_in_content_home">
                            Redefinir senha
                        </div>

                        {menuInForget === 'Email' && (
                            <>
                                <div className="col-12">
                                    <label>Email<span className="text-primary">*</span></label>
                                    <div className="input-group">
                                        <div className="input-group-text"><FontAwesomeIcon icon={faUser}/></div>
                                        <input type="text" className={`form-control z-index-0 ${emailForget.classForm}`}
                                               defaultValue={emailForget.value}
                                               onChange={(e) => ChangeEmail(e.target.value)}
                                               placeholder="Digite seu e-mail"/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <button
                                        className="btn btn_pattern fw-bold px-4 float-start mt-4"
                                        onClick={() => setMenuLoginSelected('Login')}><FontAwesomeIcon
                                        icon={faBackward}/> Voltar
                                    </button>
                                    <button
                                        className="btn btn_pattern fw-bold px-4 float-end mt-4" onClick={SendEmailGenerateHash}>Enviar <FontAwesomeIcon
                                        icon={faRightToBracket}/>
                                    </button>
                                </div>
                            </>)}
                        {menuInForget === 'Valid' && (
                            <>
                                <div className="col-12">
                                    <label>Código<span className="text-primary">*</span></label>
                                    <div className="input-group">
                                        <div className="input-group-text"><FaBarcode/></div>
                                        <input type="number" className={`form-control z-index-0 ${hash.classForm}`} defaultValue={hash.value} onChange={(e) => ChangeHash(e.target.value)} placeholder="Digite o código..."/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <button
                                        className="btn btn_pattern fw-bold px-4 float-start mt-4"
                                        onClick={() => setMenuLoginSelected('Login')}><FontAwesomeIcon
                                        icon={faBackward}/> Voltar
                                    </button>
                                    <button
                                        className="btn btn_pattern fw-bold px-4 float-end mt-4" onClick={SendHashVerify}>Verificar <FontAwesomeIcon
                                        icon={faRightToBracket}/>
                                    </button>
                                </div>
                            </>)}
                        {menuInForget === 'NewPass' && (
                            <>
                                <div className="col-12">
                                    <label>Senha<span className="text-primary">*</span></label>
                                    <div className="input-group">
                                        <div className="input-group-text">{iconPassword1}</div>
                                        <input type={typeFormSenha1}
                                               className={`form-control z-index-0 ${password1.classForm}`}
                                               defaultValue={password1.value}
                                               onChange={(e) => ChangePassword1(e.target.value)}
                                               placeholder="Digite sua senha"/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <label>Confirme a senha<span className="text-primary">*</span></label>
                                    <div className="input-group">
                                        <div className="input-group-text">{iconPassword2}</div>
                                        <input type={typeFormSenha2}
                                               className={`form-control z-index-0 ${password2.classForm}`}
                                               defaultValue={password2.value}
                                               onChange={(e) => ChangePassword2(e.target.value)}
                                               placeholder="Confirme"/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <button
                                        className="btn btn_pattern fw-bold px-4 float-start mt-4"
                                        onClick={() => setMenuLoginSelected('Login')}><FontAwesomeIcon
                                        icon={faBackward}/> Voltar
                                    </button>
                                    <button
                                        className="btn btn_pattern fw-bold px-4 float-end mt-4"
                                        onClick={UpdatePasswordUser}>Alterar <FontAwesomeIcon
                                        icon={faRightToBracket}/>
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            );
        }

        function ReturnContentCardLogin() {
            if (menuLoginSelected === 'Login') {
                return <ContentLogin/>
            } else if (menuLoginSelected === 'Register') {
                return <ContentRegister/>
            } else {
                return <ContentForgetPassword/>
            }
        }

        return (
            <div className="container d-flex justify-content-center margin_top_100 roboto-regular">
                <div className="bg_login radius_10 col-lg-10 mt-2">
                    <div className="row">
                        <div className="col-md-7 pe-0 h-100">
                        <ReturnContentCardLogin/>
                        </div>
                        <div className="col-md-5 ps-0 d-none d-md-block">
                            <div className="form-right h-100 text-white text-center">
                                <img src={logoSQ} height="100%" width="100%"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <ContainerLogin/>
    );
}

export default ContentLogin