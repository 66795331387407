import React, {useEffect, useState} from "react";
import {AiTwotoneEyeInvisible} from "react-icons/ai";
import Toast from "react-bootstrap/Toast";
import {requestBackend} from "../Home/utils";


function MessagesAcoes({csrf}){
    const [allMessages, setAllMessages] = useState([])

    useEffect(() => {
        async function getMsg(){
            const request = await requestBackend('GET','/users/all_msgs/', null, csrf)
            setAllMessages(await request['allMsg'])
        }
        getMsg()
    }, []);
    function RowCreateNewMsg(){
        const [msg, setMsg] = useState('')

        async function ClickBtnAdd(){
            if(msg.length > 0){
                const request = await requestBackend('POST','/users/all_msgs/', {'msg': msg}, csrf)
                const objReturn = await request['return']
                setAllMessages([...allMessages, {'msg': msg, 'id': objReturn.id, 'date': objReturn.date}])
            }

        }
        return (
            <>
                <div className="col-12">
                    <div className="form-floating">
                        <textarea className="form-control" value={msg} onChange={(e)=>setMsg(e.target.value)} id="formMsgAdd"></textarea>
                        <label htmlFor="formMsgAdd">Mensagem</label>
                    </div>
                </div>

                <div className="col-12 d-flex justify-content-end mt-2">
                    <button className="btn btn_pattern w-auto rounded-4" onClick={ClickBtnAdd} type="button">
                        Adicionar
                    </button>
                </div>
            </>

        );
    }

    function ContentAllMessages(){

        function CardMsgExists({objMsg}){

            async function DeleteMsgExists(){
                await requestBackend('DELETE','/users/all_msgs/' + objMsg.id + '/', null, csrf)
                setAllMessages(allMessages.filter(msg=>msg.id.toString() !== objMsg.id.toString()))
            }
            return (
                <Toast className="d-inline-block m-1" onClose={DeleteMsgExists} show={true} bg="dark">
                    <Toast.Header>
                        <strong>
                            {objMsg.date}
                        </strong>
                    </Toast.Header>
                    <Toast.Body className="">
                        <div className="col-12">{objMsg.msg}</div>
                    </Toast.Body>
              </Toast>
            );
        }
        return (
            <div className="container-fluid border mt-4 rounded-3 p-3">
                {allMessages.length === 0 && (
                    <div className="col-12 font_size_lorem font_color_green_home">
                        <AiTwotoneEyeInvisible className="me-1"/> Preencha os formularios para cadastrar!!
                    </div>)}
                {allMessages.map((objMsg, index) => (
                    <CardMsgExists objMsg={objMsg} key={index}/>
                ))}
            </div>
        );
    }

    return (
        <div className="container-fluid p-3">
            <div className="col-12 font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                Mensagens no Dash de Ações
            </div>
            <RowCreateNewMsg/>
            <ContentAllMessages/>
        </div>
    );
}

export default MessagesAcoes