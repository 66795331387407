import React, {useState, useEffect} from "react";
import imgPlan1 from "../../img_react/assine_plano1.png";
import imgPlan2 from "../../img_react/assine_plano2.png";

function Assine(props){
    function ContentAssine(){

        function BlockPlan1(){
            return (
                <div>
                    <div className="col-12 font_size_headers_in_content_home chakra-petch-regular text-center mt-5">
                        Plano 1
                    </div>
                    <div className="container-fluid boder_homezinho_2 d-flex align-items-end p-0 mb-1"
                         style={{
                             backgroundImage: `url(${imgPlan1})`,
                             backgroundSize: "cover",
                             height: "450px"
                         }}>
                        <div
                            className="col-12 bg_green_pattern_home font_size_mini_headers_in_content_home text-center chakra-petch-regular">
                            <div className="row d-flex justify-content-center">
                                <div
                                    className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex align-items-center">
                                    <div
                                        className="col-12 text-black fw-bold font_size_mini_headers_in_content_home">
                                        de
                                        <br/>
                                        <span className="text-decoration-line-through">R$799,00</span>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="col-12 fw-bold mt-1 font_size_headers_in_content_home">
                                        por
                                        <br/>
                                        R$499,00
                                    </div>
                                </div>
                                <div
                                    className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                                    <button type="button" className="btn btn_assinar text-black fw-bold">
                                        ASSINAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 font_size_lorem text-center roboto-regular mt-2 px-3">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                        sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat. Ut wisi enim ad minim
                        veniam, quis nostrud exerci tation ullamcorper suscipit
                        lobortis nisl ut aliquip ex ea commodo consequat. Duis
                    </div>
                </div>
            );
        }

        function BlockPlan2() {
            return (
                <div>
                    <div className="col-12 chakra-petch-regular font_size_headers_in_content_home text-center mt-5">
                        Plano 2
                    </div>
                    <div className="container-fluid boder_homezinho_2 d-flex align-items-end p-0 mb-1"
                         style={{
                             backgroundImage: `url(${imgPlan2})`,
                             backgroundSize: "cover",
                             height: "450px"
                         }}>
                        <div
                            className="col-12 chakra-petch-regular bg_green_pattern_home font_size_mini_headers_in_content_home text-center">
                            <div className="row d-flex justify-content-center">
                                <div
                                    className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex align-items-center">
                                    <div
                                        className="col-12 text-black fw-bold font_size_mini_headers_in_content_home">
                                        de
                                        <br/>
                                        <span className="text-decoration-line-through">R$1499,00</span>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="col-12 fw-bold mt-1 font_size_headers_in_content_home">
                                        por
                                        <br/>
                                        R$999,00
                                    </div>
                                </div>
                                <div
                                    className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                                    <button type="button" className="btn btn_assinar text-black fw-bold">
                                        ASSINAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 font_size_lorem text-center mt-2 px-3 roboto-regular">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                        sed diam nonummy nibh euismod tincidunt ut laoreet
                        dolore magna aliquam erat volutpat. Ut wisi enim ad minim
                        veniam, quis nostrud exerci tation ullamcorper suscipit
                        lobortis nisl ut aliquip ex ea commodo consequat. Duis
                    </div>
                </div>
            );
        }

        return (
            <div className="container-fluid height_content margin_top_100">
            <div className="container h-100 text-white">
                    <div className="row d-flex justify-content-between">
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                            <BlockPlan1/>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                            <BlockPlan2/>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    return (
        <ContentAssine/>
    );
}

export default Assine