import {BrowserRouter, Routes, Route} from 'react-router-dom'

import AdminMt from './pages/AdminMt'
import AppHome from "./pages/Home/components";
import GerenciamentoUsers from "./pages/GerenciamentoUsers";
function RoutesApp(){
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/AdminMt' element={<AdminMt/>}/>
                <Route path='/' element={<AppHome/>}/>
                <Route path='/GerenciamentoUsers' element={<GerenciamentoUsers/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesApp