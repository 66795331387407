import React, {useEffect, useState} from "react";
import Toast from 'react-bootstrap/Toast';
import cnnImage from "../../img_react/test_tt.jpg"
import Swal from "sweetalert2";
import {requestBackend, sendFilesBackEnd} from "../Home/utils";
import { AiTwotoneEyeInvisible } from "react-icons/ai";
function Twitter({csrf}){
    const [allTTSaved, setAllTTSaved] = useState([])

    useEffect(() => {
        async function getTT(){
            const request = await requestBackend('GET','/users/tt_in_acoes/', null, csrf)
            setAllTTSaved(await request['allTT'])
        }
        getTT()
    }, []);
    function ContentAllTt(){
        function CardTtExists({objTT}){

            async function DeleteTTExists(){
                await requestBackend('DELETE','/users/tt_in_acoes/' + objTT.id + '/', null, csrf)
                setAllTTSaved(allTTSaved.filter(tt=>tt.id.toString() !== objTT.id.toString()))
            }
            return (
              <Toast className="d-inline-block m-1" onClose={DeleteTTExists} show={true} bg="dark">
                <Toast.Header>
                    <strong>
                        <img src={objTT.image} className="rounded-circle" style={{width: "30px", height: "30px"}}/>
                    </strong>

                </Toast.Header>
                  <Toast.Body className="text-truncate">{objTT.url}</Toast.Body>
              </Toast>
            );
        }


        return (
            <div className="container-fluid border mt-4 rounded-3 p-3">
                {allTTSaved.length === 0 && (
                    <div className="col-12 font_size_lorem font_color_green_home">
                        <AiTwotoneEyeInvisible className="me-1"/> Preencha os formularios para cadastrar!!
                    </div>)}
                {allTTSaved.map((objTT, index) => (
                    <CardTtExists objTT={objTT} key={index}/>
                ))}
            </div>
        );
    }

    function RowCreateNewTT(){
        const [urlTT, setUrlTT] = useState('')
        const [imageTT, setImageTT] = useState(null)
        const [base64Image, setBase64Image] = useState(null)
        async function ClickBtnSave(){
            if(!(urlTT === '' || imageTT === null)){
                const formData = new FormData()
                formData.append('imageTT', imageTT)
                formData.append('urlTT', urlTT)
                const request = await sendFilesBackEnd('/users/tt_in_acoes/', formData, csrf)
                const newId = await request['newId']
                const objAppend = {id: newId, url: urlTT, image: base64Image}
                setAllTTSaved([...allTTSaved, objAppend])
                Swal.fire({
                    text: 'Deu Boaaaa !!',
                    icon: "success"
                })
            }
            else{
                Swal.fire({
                    text: 'Preenche tudo aeee !!',
                    icon: "error"
                })
            }
        }

        function ChangeImageTT(e){
            const file = e.target.files[0]
            const reader = new FileReader();
            reader.onloadend = () => {
              setBase64Image(reader.result);
            };
            reader.readAsDataURL(file);
            setImageTT(file)
        }
        return (
            <>
                <div className="row">
                    <div className="col-8">
                        <div className="form-floating mt-3">
                            <input type="text" id="floatingInput" value={urlTT} className="form-control" onChange={(e)=>setUrlTT(e.target.value)}/>
                            <label htmlFor="floatingInput">Url do Twitter:</label>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="">
                            <label htmlFor="formFile" className="form-label">Foto de Visualização</label>
                            <input className="form-control" type="file" accept="image/*" id="formFile" onChange={ChangeImageTT}/>
                        </div>
                    </div>
                </div>

                <div className="col-12 d-flex justify-content-end mt-2">
                    <button className="btn btn_pattern w-auto rounded-4" onClick={ClickBtnSave} type="button">
                        Salvar
                    </button>
                </div>
            </>
        );
    }

    return (
        <div className="container-fluid p-3">
            <div className="col-12 font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                Twitter no Dash de Ações
            </div>
            <div className="container-fluid rounded-3">
                <RowCreateNewTT/>
                <ContentAllTt/>
            </div>
        </div>
    );
}

export default Twitter