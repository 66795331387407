import { TiDelete } from "react-icons/ti";
import {useEffect, useState} from "react";
import {requestBackend} from "../Home/utils";
import { RiVerifiedBadgeFill } from "react-icons/ri";
function AllUsers({csrf}){

    function TableUsers(){

        const [allUsers, setAllUsers] = useState([])

        useEffect(() => {
            async function getUsers(){
                const request = await requestBackend('GET','/users/all_users/', null, csrf)
                const users = await request['allUsers']
                setAllUsers(users)
            }
            getUsers()
        }, []);
        function RowTable({objUser}){
            const [stateUser, setStateUser] = useState(objUser)

            function ReturnStatusUser(){
                if(stateUser.status === 'Ativo'){
                    return (
                        <div className="px-2 font_size_footer bg-opacity-25 bg-success text-success rounded-4 w-auto">
                            <RiVerifiedBadgeFill className="me-2 mb-1" size={20}/>Ativo</div>
                    );
                }
                else{
                    return (
                        <div className="px-2 font_size_footer bg-opacity-25 bg-danger text-danger rounded-4 w-auto">
                            <TiDelete className="me-2 mb-1" size={20}/>Inativo</div>
                    );
                }
            }

            async function UpdateStatusUser(newStatus){
                // requisitar back-end pra atualizar
                await requestBackend('POST', '/users/all_users/', {idUser: stateUser.id, status: newStatus}, csrf)
                setStateUser({...stateUser, status: newStatus})
            }

            function ReturnActionsUser(){
                if(stateUser.status === 'Inativo'){
                    return (
                        <div className="px-2 cursor_pointer font_size_footer bg-opacity-25 bg-success text-success rounded-4 w-auto" onClick={()=>UpdateStatusUser('Ativo')}>
                            <RiVerifiedBadgeFill className="me-2 mb-1" size={20}/>Ativar</div>
                    );
                }
                else{
                    return (
                        <div className="px-2 cursor_pointer font_size_footer bg-opacity-25 bg-danger text-danger rounded-4 w-auto" onClick={()=>UpdateStatusUser('Inativo')}>
                            <TiDelete className="me-2 mb-1" size={20}/>Inativar</div>
                    );
                }
            }

            return (
                <tr>
                    <td>
                        <div className="form-check">
                            <input className="form-check-input" readOnly={true} type="checkbox" checked={stateUser.checked}/>
                        </div>
                    </td>
                    <td>{stateUser.name}</td>
                    <td>{stateUser.email}</td>
                    <td className="text-center">{stateUser.fone}</td>
                    <td className="text-center"><ReturnStatusUser/></td>
                    <td className="text-center"><ReturnActionsUser/></td>
                </tr>
            );
        }
        return (
            <table className="table table-hover">
                <thead>
                    <tr className="chakra-petch-regular">
                        <th className="d-flex align-items-center">
                            <div className="form-check">
                                <input className="form-check-input" readOnly={true} type="checkbox" value="" id="flexCheckDefault"/>
                            </div>
                        </th>
                        <th>Nome Completo</th>
                        <th>Email</th>
                        <th className="text-center">Telefone</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {allUsers.map((objUser, index) => (
                        <RowTable objUser={objUser} key={index}/>
                    ))}

                </tbody>
            </table>
        );
    }

    return (
        <div className="container-fluid p-3">
            <div className="col-12 font_size_headers_in_content_home font_color_green_home chakra-petch-regular">
                Gerenciar Usuários
            </div>
            <div className="container-fluid bg-white rounded-3">
                <TableUsers/>
            </div>
        </div>
    );
}

export default AllUsers