import React from "react";
import imgHome2 from '../../img_react/bg_home_2.png';
import imgPlan1 from '../../img_react/plan1.png';
import imgPlan2 from '../../img_react/plan2.png';
import imgHome from '../../img_react/img_home.png';

function ContainerBaseHome(props){
    document.title = 'SuperQuant'
    function ContentHome(){

        function About(){
            return (
                <div className="container">
                    <div className="row h-100">
                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12" style={{height: "60vh"}}>
                            <div className="container-fluid position-relative">
                                <img src={imgHome} className="position-absolute mt-5"
                                     style={{left: '-20vw', width: '170%'}}/>
                            </div>
                        </div>

                        <div
                            className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 d-flex align-items-center height_content">

                            <div className="container-fluid">
                                <div className="col-12 font_color_green_home chakra-petch-regular fw-bold font_size_headers_in_content_home">
                                    SOBRE A PLATAFORMA
                                </div>

                                <div className="col-12 text-white font_size_lorem mt-2 roboto-regular" style={{textAlign: "justify"}}>

                                    No dinâmico mundo dos investimentos, tomar decisões assertivas é a chave para o
                                    sucesso. Mas como saber para qual lado o vento está soprando? Com o <b>SuperQuant</b>, você
                                    terá acesso à análise em tempo real da movimentação dos players globais, os grandes
                                    investidores que ditam o ritmo do mercado.
                                    <br/>
                                    <br/>

                                    Entenda o jogo e faça jogadas inteligentes com o <b>SuperQuant</b>:
                                    <br/>
                                    <br/>
                                    • Acompanhe as ações dos players em tempo real: monitore cada passo dos grandes
                                    investidores e identifique suas estratégias.
                                    <br/>
                                    • Descubra as tendências antes que todos: antecipe as oscilações do mercado e tome
                                    decisões de compra e venda com mais precisão.
                                    <br/>
                                    • Negocie com base em informações confiáveis: utilize dados concretos e análises
                                    precisas para embasar suas decisões de investimento.
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            );
        }

        function Why(){
            return (
                <div className="container-fluid font_calibri d-flex align-items-center">
                    <div className="container text-white mt-4">
                        <div className="col-12 fw-bold font_size_headers_in_content_home text-center chakra-petch-regular">
                            POR QUE USAR O SUPER QUANT?
                        </div>

                        <div className="row d-flex justify-content-around mt-4 roboto-regular">
                            <div className="mt-1 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 boder_homezinho_2">
                                <div className="col-12 text-center font_size_mini_headers_in_content_home chakra-petch-regular">
                                    + Rentabilidade
                                </div>

                                <div className="col-12 font_size_lorem" style={{textAlign: "justify"}}>
                                    Invista com mais segurança, minimize seus riscos e maximize seus lucros com base em dados concretos.
                                    <br/>
                                    O <b>SuperQuant</b> vasculha o mercado em busca de oportunidades de investimento com alto potencial de retorno, utilizando algoritmos avançados e inteligência artificial. Isso significa que você não precisa perder tempo pesquisando manualmente, pois o <b>SuperQuant</b> faz o trabalho duro para você.
                                </div>

                            </div>

                            <div className="mt-1 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 boder_homezinho_2">
                                <div className="col-12 text-center font_size_mini_headers_in_content_home chakra-petch-regular">
                                    + Segurança

                                </div>

                                <div className="col-12 font_size_lorem" style={{textAlign: "justify"}}>
                                    Obtenha uma visão abrangente do mercado global e identifique as oportunidades mais promissoras.
                                    <br/>
                                    O <b>SuperQuant</b> utiliza técnicas avançadas de análise de big data para detectar tendências emergentes antes que se tornem evidentes para a maioria dos investidores, permitindo que você se posicione estrategicamente antes da valorização dos preços. Além disso, nosso sistema oferece insights personalizados para ajudá-lo a tomar decisões mais seguras.
                                </div>
                            </div>

                            <div className="mt-1 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 boder_homezinho_2">
                                <div className="col-12 text-center font_size_mini_headers_in_content_home chakra-petch-regular">
                                    + Inteligência
                                </div>

                                <div className="col-12 font_size_lorem" style={{textAlign: "justify"}}>
                                    Tome decisões mais inteligentes, faça escolhas assertivas e consistentes com base em análises precisas e atualizadas.
                                    <br/>
                                    O <b>SuperQuant</b> monitora continuamente seus investimentos e o alerta sobre quaisquer eventos ou mudanças que possam afetar seus resultados. Isso garante que você esteja sempre no controle de seus investimentos e possa tomar as medidas necessárias para proteger seu patrimônio.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        function App(){
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 d-flex align-items-center height_content">
                            <div className="container-fluid h-100 d-flex align-items-center">
                                <div className="container-fluid">
                                    <div
                                        className="col-12 chakra-petch-regular font_color_green_home fw-bold font_size_headers_in_content_home chakra-petch-regular">
                                        NOSSO APLICATIVO
                                    </div>

                                    <div className="col-12 text-white font_size_lorem mt-2 roboto-regular" style={{textAlign: "justify"}}>
                                        Invista com inteligência e posicione-se estrategicamente para maximizar seus retornos.
                                        <br/>
                                        Proteja seu patrimônio e tenha a certeza de estar no caminho certo para realizar seus sonhos. O <b>SuperQuant</b> oferece análises detalhadas e insights precisos para garantir que seu futuro financeiro esteja bem encaminhado.

                                        <br/>
                                        O <b>SuperQuant</b> monitora indicadores econômicos, notícias e eventos, proporcionando uma visão completa do mercado global.
                                        <br/>
                                        Garanta maior tranquilidade e qualidade de vida com investimentos seguros e rentáveis. Com o <b>SuperQuant</b>, você tem a ferramenta ideal para alcançar seus objetivos financeiros com eficiência.
                                        <br/>
                                        Baixe o aplicativo agora e transforme seu futuro financeiro.
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12" style={{height: "50vh"}}>
                            <div className="container-fluid position-relative mt-5">
                                <img src={imgHome2} className="position-absolute" style={{width: '120%', top: '-10%', left: "-3vw"}}/>
                            </div>
                        </div>


                    </div>
                </div>
            );
        }

        function Plans() {
            return (
                <div className="container chakra-petch-regular">
                    <div className="height_content w-100 d-flex align-items-center">
                        <div className="container-fluid text-white">
                            <div className="col-12 fw-bold mt-4 font_size_headers_in_content_home text-center">
                                NOSSOS PLANOS
                            </div>

                            <div className="container mt-3">
                                <div className="row boder_homezinho_2 d-flex"
                                     style={{backgroundImage: `url(${imgPlan1})`, backgroundSize: "cover"}}>
                                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 h-100">

                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 h-100 bg_green_pattern_home px-3" style={{flex: `1`}}>
                                        <div className="col-12 fw-bold mt-2 font_size_headers_in_content_home">
                                            Plano 1
                                        </div>

                                        <div
                                            className="col-12 text-black fw-bold font_size_mini_headers_in_content_home">
                                            de
                                            <br/>
                                            <span className="text-decoration-line-through">R$799,00</span>
                                        </div>

                                        <div className="col-12 fw-bold mt-1 font_size_headers_in_content_home">
                                            por
                                            <br/>
                                            R$499,00
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-5 d-flex boder_homezinho_2" style={{backgroundImage: `url(${imgPlan2})`, backgroundSize: "cover"}}>
                                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 h-100">

                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 h-100 bg_green_pattern_home px-3">
                                        <div className="col-12 fw-bold mt-2 font_size_headers_in_content_home">
                                            Plano 2
                                        </div>

                                        <div
                                            className="col-12 text-black fw-bold font_size_mini_headers_in_content_home">
                                            de
                                            <br/>
                                            <span className="text-decoration-line-through">R$1399,00</span>
                                        </div>

                                        <div className="col-12 fw-bold mt-1 font_size_headers_in_content_home">
                                            por
                                            <br/>
                                            R$999,00
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        function Footer(){
            return (
                <div className="container-fluid p-0 bg-white pt-5 mt-5 roboto-regular">
                    <div className="row d-flex justify-content-center">
                        <div className="col-9 font_size_footer">
                            Investir no mercado financeiro envolve riscos significativos, incluindo a perda de capital.
                            Antes de tomar qualquer decisão de investimento, é essencial entender os riscos envolvidos e
                            tomar precauções. Informe-se sobre os diferentes tipos de investimentos, seus riscos e
                            retornos esperados. Leia materiais educativos, participe de seminários e siga fontes
                            confiáveis de informação financeira. Diversifique seus investimentos para reduzir o risco,
                            evitando concentrar todo seu capital em um único ativo ou setor. Realize sua própria análise
                            e não se baseie apenas em recomendações de terceiros. Utilize ferramentas de rastreamento e
                            análise, como o <b>SuperQuant</b>, para obter insights, mas sempre avalie se as
                            oportunidades são adequadas ao seu perfil de investidor.
                            <br/>
                            <br/>
                            Conheça seu perfil de investidor e invista conforme sua tolerância ao risco, considerando
                            fatores como idade, objetivos financeiros, horizonte de investimento e necessidade de
                            liquidez. Considere consultar um consultor financeiro ou gestor de investimentos para
                            orientações personalizadas e para ajudar a estruturar um portfólio que atenda às suas
                            necessidades e objetivos. O mercado financeiro é dinâmico e sujeito a mudanças rápidas, por
                            isso mantenha-se atualizado com notícias econômicas e financeiras, revisando periodicamente
                            suas estratégias e portfólio. Estabeleça limites claros para perdas e ganhos, utilizando
                            estratégias como stop-loss e take-profit, e monitore regularmente seus investimentos,
                            ajustando conforme necessário.
                            <br/>
                            <br/>
                            As informações fornecidas pelo <b>SuperQuant</b> são baseadas em análises de dados e leitura
                            de mercado, mas não garantem resultados específicos. Toda decisão de investimento deve ser
                            tomada com cautela e responsabilidade. O desempenho passado não é indicativo de resultados
                            futuros. Invista de maneira consciente e responsável. O <b>SuperQuant</b> está aqui para
                            ajudar a identificar oportunidades, mas o controle final e a responsabilidade sobre os
                            investimentos são sempre seus.
                        </div>
                    </div>
                    <div className="col-12 text-center font_size_footer mt-5">
                        Copyright © 2024 Superquant.com.br
                    </div>
                </div>
            );
        }

        return (
            <div className="">
                <About/>
                <Why/>
                <Plans/>
                <App/>
                <Footer/>
            </div>
        );
    }

    return (
        <ContentHome/>
    );
}

export default ContainerBaseHome