import $ from "jquery";
import { Modal } from 'react-bootstrap';
export const requestBackend = async (method, url, dataBody, csrf) => {
    let datas_return = null
    let datasBody = dataBody === null ? {} : {'datasFront': JSON.stringify(dataBody)}
    await $.ajax({
        url: url, // Caminho do Ajax
        type: method, // http method
        headers:{'X-CSRFToken':csrf},
        dataType: "json",
        data: datasBody, // Envia form pela solicitação do POST
        success: function (data) {
            datas_return = data
        },
        failure: function () {
        }
    })
    return datas_return
}

export const sendFilesBackEnd = async (url, file, csrf) => {
    let datas_return = null
    await $.ajax({
        url: url, // Caminho do Ajax
        type: "POST", // http method
        headers:{'X-CSRFToken':csrf},
        dataType: "json",
        data: file, // Envia form pela solicitação do POST
        processData: false,
        contentType: false,
        success: function (data) {
            datas_return = data
        },
        failure: function () {
            alert('Algo deu errado! verifique e tente novamente.')
        }
    })
    return datas_return
}

export const getCsrfToken = async () => {
    let datasRequest = await requestBackend('GET','/users/get-token/', null, null)
    return await datasRequest['csrfToken']
}

export const userLogged = async () => {
    const datasRequest = await requestBackend('GET','/users/user_logged/', null, null)
    return await datasRequest['userLogged']
}



export const getMenuSelected = async () => {
    const datasRequest = await requestBackend('GET','/users/return_menu_selected/', null, null)
    return await datasRequest['menu_selected']
}

export const ModalConfirm = ({show, onHide, message, header, onConfirm}) => {
    return (
        <Modal show={show} onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title>{header}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{message}</Modal.Body>
          <Modal.Footer>
              <div className="col-12 d-flex justify-content-end mt-2">
                  <button type="button" className="btn btn-danger px-4 py-2 me-2" onClick={onHide}>Cancelar
                  </button>
                  <button type="button" className="btn btn-success px-4 py-2" onClick={onConfirm}>Confirmar</button>
              </div>
          </Modal.Footer>
        </Modal>
    );
}