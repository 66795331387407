
import React from "react";
import imgC from "../../img_react/carteira.png";
import imgMD from "../../img_react/meus_dados.png";
import imgMP from "../../img_react/meu_plano.png";

function ContentCarteira(props){

    function ContentCarteira(){
        return (
            <div className="container-fluid height_content margin_top_100">
                <div className="container h-100 text-white">
                    <div className="row d-flex justify-content-between">

                        <div
                            className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 boder_homezinho_2 d-flex align-items-end p-0 mt-5 cardzinhos_screen_dash mb-1"
                            style={{backgroundImage: `url(${imgC})`, backgroundSize: "cover", height: "350px"}}>
                            <div
                                className="col-12 chakra-petch-regular bg_green_pattern_home font_size_mini_headers_in_content_home text-center">
                                Carteira
                            </div>
                        </div>

                        <div
                            className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 boder_homezinho_2 d-flex align-items-end p-0 mt-5 cardzinhos_screen_dash mb-1"
                            style={{backgroundImage: `url(${imgMD})`, backgroundSize: "cover", height: "350px"}}>
                            <div
                                className="col-12 chakra-petch-regular bg_green_pattern_home font_size_mini_headers_in_content_home text-center">
                                Meus Dados
                            </div>
                        </div>

                        <div
                            className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 boder_homezinho_2 d-flex align-items-end p-0 mt-5 cardzinhos_screen_dash mb-1"
                            style={{backgroundImage: `url(${imgMP})`, backgroundSize: "cover", height: "350px"}}>
                            <div className="col-12 chakra-petch-regular bg_green_pattern_home font_size_mini_headers_in_content_home text-center">
                                Meu Plano
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }

    return (
        <ContentCarteira/>
    );
}

export default ContentCarteira