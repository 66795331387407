import React, {useState, useEffect} from "react";
import imgID from "../../img_react/indice_dolar.png";
import imgAO from "../../img_react/acao_opcao.png";
import imgCE from "../../img_react/conteudo_edu.png";
import imgMC from "../../img_react/minha_carteira.png";
import {getCsrfToken, userLogged} from "./utils";

function ContentDashboard({ csrf, setMenu, userLogged}){

    useEffect(()=> {
        if(!userLogged){
            setMenu('Login')
        }
    }, [])

    function ContainerDashboard(){

        return (
            <div className="container-fluid height_content margin_top_100">
                <div className="container h-100 text-white">
                    <div className="row d-flex justify-content-between">

                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 boder_homezinho_2 d-flex align-items-end p-0 mt-5 cardzinhos_screen_dash mb-1" style={{backgroundImage: `url(${imgID})`, backgroundSize: "cover", height: "350px"}} onClick={()=>window.location.href='/dash_futuros/'}>
                            <div
                                className="col-12 bg_green_pattern_home font_size_mini_headers_in_content_home text-center chakra-petch-regular">
                                Índice Dolar
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 boder_homezinho_2 d-flex align-items-end p-0 mt-5 cardzinhos_screen_dash mb-1" style={{backgroundImage: `url(${imgAO})`, backgroundSize: "cover", height: "350px"}} onClick={()=>window.location.href='/dash_acoes/'}>
                            <div
                                className="col-12 bg_green_pattern_home font_size_mini_headers_in_content_home text-center chakra-petch-regular">
                                Ações e Opções
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 boder_homezinho_2 d-flex align-items-end p-0 mt-5 cardzinhos_screen_dash mb-1" style={{backgroundImage: `url(${imgCE})`, backgroundSize: "cover", height: "350px"}}>
                            <div
                                className="col-12 bg_green_pattern_home font_size_mini_headers_in_content_home text-center chakra-petch-regular">
                                Conteúdo Educacional
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 boder_homezinho_2 d-flex align-items-end p-0 mt-5 cardzinhos_screen_dash mb-1" onClick={()=>setMenu('Carteira')} style={{backgroundImage: `url(${imgMC})`, backgroundSize: "cover", height: "350px"}}>
                            <div
                                className="col-12 bg_green_pattern_home font_size_mini_headers_in_content_home text-center chakra-petch-regular">
                                Minha Carteira
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }

    return (
        <ContainerDashboard/>
    );
}

export default ContentDashboard